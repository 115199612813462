<template>
  <div class="container mt-5" style="background-color:white">
    <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
    <div style="text-align: right">
      <vue-excel-xlsx
      v-if="statustest"
      class="btn btn-success"
                id="excel"
                :data="listexcel"
                :columns="columns"
                :file-name="filename"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
              >
              <i class="fa fa-file-excel"></i>  Export Excel
              </vue-excel-xlsx>&nbsp;
    </div>
    <table class="table table-bordered mt-3 mb-5" v-if="statustest">
        <tbody>
          <tr>
            <th style="background-color: #ffb454; vertical-align: top">
              รูปประโยค
            </th>
           
            <th style="background-color: #ffb454; vertical-align: top">
              officer1
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              officer2
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              officer3
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              answer
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              label
            </th>
          </tr>
              <tr v-for="(k, i) in list" :key="i">
                <td style="background-color: rgb(255, 180, 84)">
                  <span v-html="k.sentence_rulebase"></span><br /><br />
                  <span v-html="getsentent(k.rule_based_name)"></span>
                  <br /><br />
                  <span></span>
                </td>
                <td :style="getcolor(k.one_result)">
                  <span v-if="k.one_result == 1">เกินจริง</span
                  ><span v-if="k.one_result == 9">ไม่เกินจริง</span
                  >
                </td>

                <td :style="getcolor(k.two_result)">
                  <span v-if="k.two_result == 1">เกินจริง</span
                  ><span v-if="k.two_result == 9">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.three_result)">
                  <span v-if="k.three_result == 1">เกินจริง</span
                  ><span v-if="k.three_result == 9">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.answertest)">
                  <span v-if="k.answertest == 1">เกินจริง</span
                  ><span v-if="k.answertest == 9">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.type_rulebasedId)">
                  <a :href="'/productresult/'+k.protoken_id"> <span v-if="k.type_rulebasedId == 1"> 
                    เกินจริง
        </span
                  ><span v-if="k.type_rulebasedId == 2">ไม่เกินจริง</span
                  >
                </a>
                </td>
              </tr>
        </tbody>
      </table>
  </div>
</template>

<script>

import KeywordService from '../services/KeywordService'
import axios from 'axios';
import RuleBasedService from '../services/RuleBasedService'
import DictService from '../services/DictService'
import MapRuleBasedService from '../services/MapRuleBasedService'
import LinkService from '../services/LinkService'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "App",
  components: {ClipLoader},
  data() {
    return {
      type: 0,
      list: [],
      url:'',
      file:'',
      status:false,
      urlPath:'',
      title:'',
      data:{},
      pro_id:0,
      category:[],
      pageOfItems: [],
      customLabels,
      keywordlist:[],
      statustest:false,
      color: '#5bc0de',
      size: '50px',
      loading:true,
      filename:'รายงานผลลัพธ์การเทรน',
      columns: [],
      listexcel:[]
    };
  },
  methods: {
    generatecolumn() {
      this.columns = [];
      this.columns.push(
        {
          label: "รูปประโยค",
          field: "sen",
          align: "center",
        },
        {
          label: "officer1",
          field: "one_resultname",
        },
        {
          label: "officer2",
          field: "two_resultname",
        },
        {
          label: "officer3",
          field: "three_resultname",
        },
        {
          label: "answer",
          field: "answertestname",
        },
        {
          label: "label",
          field: "type_rulebasedIdname",
        },
      );
    },
    getcolor(id){
      var bg = 'background-color: rgb(249, 189, 187)'
if (id == 1) {
  bg = 'background-color: rgb(163, 233, 164)'
}
return bg
    },
    getsentent(data){
      data = JSON.parse(data)
      // var text = ''
      var arr =[]
      for (let d = 0; d < data.length; d++) {
        // text += data[d]
        arr.push(data[d])
      }
      // console.log(arr);
      var arrtxt = String(arr)
      // console.log(arrtxt);
      arrtxt = arrtxt.replaceAll(",","<span style='color:red;'> | </span>")
      // console.log(arrtxt);
return arrtxt
    },
     onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
      window.scrollTo(0,0);
    },
    deleteScopus(){
      console.log(this.pro_id);
      var data ={
        status: 0
      }
      MapRuleBasedService.deletemap_rule_based(this.pro_id,data).then((res)=>{
        console.log(res.data);
        document.getElementById("closedDeleteScopus").click();
        this.getcategory();
        alert('บันทึกสำเร็จ')
      })
    },
    save() {
      // console.log(this.data);
      if (this.data.name == null || this.data.cat_id == "") {
        alert("กรุณากรอกข้อความ");
      } else {
        var prodata = {
          id:this.data.id,
          name: this.data.name,
          status:1,
        };
        // console.log(prodata); 
        if (this.pro_id == 0) {
          KeywordService.createkeyword(prodata).then(() => {
          DictService.createdict(prodata).then((res) => {
            // console.log(res.data );
            RuleBasedService.createdcolumnrule_based(res.data.id).then(() => {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          });
        });
      });
        } else {
          KeywordService.updatekeyword(this.pro_id,prodata).then(() => {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          });
            }
      }
    },
    token(text){
      var tokenize = ''
      axios.get(LinkService.getpythonlink()+'/tokenkeyword?text=' + text).then((res) => {
        // this.tokenize = res.data
        // console.log(res.data);
        tokenize = res.data
      });
      return tokenize
    },
    updatetoken(data,type){
      // console.log(data);
      var tokenize= ''
      // data.name = data.name.replaceAll(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
      // data.name = data.name.replaceAll(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');

      axios.get(LinkService.getpythonlink()+'/tokenkeyword?text=' + data.name).then((res) => {
        this.tokenize = res.data
        // console.log(res.data);
        tokenize = res.data.replaceAll(" ",'')
        tokenize = tokenize.replaceAll(".",'')
        tokenize = tokenize.replaceAll("''",'')
        tokenize = tokenize.replaceAll(",,",',')
        tokenize = tokenize.replaceAll('[','')
        tokenize = tokenize.replaceAll(']','')
        tokenize = tokenize.replaceAll("'",'')
        tokenize = tokenize.replaceAll(",",' | ')
      var tokendata = {
        name:data.name,
        token: tokenize,
        };
        // console.log(tokendata);
        // console.log(data.id);
        KeywordService.updatekeyword(data.id, tokendata).then(() => {
          // console.log(res.data);
          if (type == 'update') {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          }
        });
      });
    },
    getAllIndexes(arr, val) {
      var indexes = [],
        i = -1;
      while ((i = arr.indexOf(val, i + 1)) != -1) {
        indexes.push(i);
      }
      return indexes;
    },
    findfda(data) {
    // console.log(data);
      var text = ['หมายเลขใบอนุญาต/อย.','เลขจดแจ้ง','เลขอย.','อย.']
      var end = ['จำนวนสินค้า','ส่วนประกอบ','น้ำหนัก','วันหมดอายุ','คลังสินค้าโปรโมชั่น','ปริมาณ','ประเทศต้นกำเนิดสินค้า','ส่วนผสมของผลิตภัณฑ์','ประเภทอาหาร','________________________ ']
      var findfda = data
      for (let t = 0; t < end.length; t++) {
        // console.log(findfda.indexOf(end[t]));
        // console.log(findfda.indexOf(text[0]));
        // console.log(findfda.indexOf(end[t]));
        if (findfda.indexOf(end[t]) != -1) {
          // console.log(end[t]);
          findfda = findfda.substring(findfda.indexOf(text[0]),findfda.indexOf(end[t]));
        }
         

      }
      // console.log(findfda);

      findfda = findfda.replaceAll("หมายเลขใบอนุญาต/อย.", "");
      findfda = findfda.replaceAll("เลขจดแจ้ง", "");
      findfda = findfda.replaceAll("เลขอย.", "");
      findfda = findfda.replaceAll("อย.", "");

      findfda = findfda.replaceAll("-", "");
      findfda = findfda.replaceAll("–", "");
      // console.log(findfda);
      // var regex = /\d+/g;
      // var matches = findfda.match(regex);  // creates array from matches
      // console.log(matches[0]);
      return findfda
    },
    // findfda(data) {
    //   var text = ["หมายเลขใบอนุญาต","หมายเลขใบอนุญาต/อย.","อย."];
    //   // var end = ['']
    //   var findfda = data;
    //   for (let t = 0; t < text.length; t++) {
    //     findfda = findfda.substring(findfda.indexOf(text[t]), data.length);
    //     var endindex = this.getAllIndexes(findfda, " ");
    //     findfda = findfda.substring(findfda.indexOf(text[t]), endindex[1]);
    //   }
    //   // console.log(findfda);

    //   for (let v = 0; v < text.length; v++) {
    //   findfda = findfda.replaceAll(text[v], "");
    //   }
    //   // findfda = findfda.replaceAll(/[^\d.-]/g, '');
    //   findfda = findfda.replaceAll("-", "");
    //   findfda = findfda.replaceAll("–", "");
    //   findfda = findfda.replaceAll(" ", "");
    //   // console.log(findfda);
    //   // var regex = /\d+/g;
    //   // var matches = findfda.match(regex);  // creates array from matches
    //   // console.log(matches[0]);
    //   return findfda;
    // },
    getfda(content){
        var tt = content.split(' ')

    // console.log(tt);
    var arr = []
    for (let l = 0; l < tt.length; l++) {
      var findfda = tt[l].replaceAll('-', '');
      findfda = findfda.replaceAll("–", "");
      findfda = findfda.replaceAll(".", "");
    findfda = findfda.replaceAll(/[^\d.-]/g, '');
    // console.log(findfda);
    if (findfda) {
      if (findfda.length >= 13) {
      arr.push(findfda)
      }
    }
      
    }
    // console.log(arr);
    return arr;
    },
    
    async getcategory(){
var selectpro = {
        id: 1,
      };
await MapRuleBasedService.rulebasedexpert(selectpro).then(async (res)=>{
  // console.log(res.data);
  this.list = res.data
  for (let l = 0; l < this.list.length; l++) {
    var resulttrue = 0
    var resultfalse = 0
    // console.log(this.list[l].one_result);
    // console.log(this.list[l].two_result);
    // console.log(this.list[l].three_result);
    if (this.list[l].one_result == 1 && this.list[l].two_result == 1 && this.list[l].three_result == 1) {
      resulttrue = 3
    }
    if (this.list[l].one_result == 9 && this.list[l].two_result == 9 && this.list[l].three_result == 9) {
      resultfalse = 3
    }
    if (resulttrue != 3 && resultfalse != 3) {
      if (this.list[l].one_result == 1){
        this.list[l].one_resultname = 'เกินจริง'
      } 
      if (this.list[l].two_result == 1){
        this.list[l].two_resultname = 'เกินจริง'
      } 
      if (this.list[l].three_result == 1){
        this.list[l].three_resultname = 'เกินจริง'
      } 
      if (this.list[l].one_result == 9){
        this.list[l].one_resultname = 'ไม่เกินจริง'
      } 
      if (this.list[l].two_result == 9){
        this.list[l].two_resultname = 'ไม่เกินจริง'
      } 
      if (this.list[l].three_result == 9){
        this.list[l].three_resultname = 'ไม่เกินจริง'
      } 
      if (this.list[l].answertest == 1){
        this.list[l].answertestname = 'เกินจริง'
      } 
      if (this.list[l].answertest == 9){
        this.list[l].answertestname = 'ไม่เกินจริง'
      } 
      if (this.list[l].type_rulebasedId == 1){
        this.list[l].type_rulebasedIdname = 'เกินจริง'
      } 
      if (this.list[l].type_rulebasedId == 2){
        this.list[l].type_rulebasedIdname = 'ไม่เกินจริง'
      } 
     this.listexcel.push(this.list[l]) 
    }
    
  }
  // console.log(this.listexcel);
  this.loading = false
  this.statustest = true
})
// console.log(this.listexcel);

      // })
    },
    getid(id) {
      // console.log(id);
      this.pro_id = id;
    },
  },
  mounted() {
    this.getcategory()
    this.generatecolumn()
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0px;
}
/* .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: #CFD5EA;
} */
.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #CFD5EA;
  /* color: white; */
}
.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #E7EBF5;
  /* color: white; */
}
</style>
