<template>
  <div class="container mt-5" style="background-color:white">
    <p>
  <a :class="expertclass" @click="changtype(1)" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    Expert
  </a>&nbsp;
  <a :class="rulebaseclass" @click="changtype(2)" data-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1">
    Base
  </a>
</p>
    <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
    <!-- <div style="text-align:right"> <button @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddScopus"
           type="submit" class="mb-3 btn btn-success">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button></div> -->

    <!-- <div class="form-group">
      <label for="exampleInputEmail1">จำนวนการเทส (%)</label>
      <input
        type="number"
        class="form-control"
        id="exampleInputEmail1"
        min="10"
        max="100"
        v-model="testno"
      />
    </div> -->
    <!-- <button type="submit" class="btn btn-success btn-sm" @click="gettest()">
      เทส
    </button> -->

    <table class="table table-bordered mt-5" v-if="statustestacc">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">เกินจริง</th>
          <th scope="col">ไม่เกินจริง</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">เกินจริง</th>
          <td style="text-align:center">
            <a href="#" @click="nextpage('tp')">
             {{truepos}}</a>
            </td>
          <td style="text-align:center">
            <a href="#" @click="nextpage('fp')">
             {{falsepos}}
          </a>
            </td>
        </tr>
        <tr> 
          <th scope="row">ไม่เกินจริง</th>
          <td style="text-align:center">
            <a href="#" @click="nextpage('fn')">
              {{falseneg}}</a>
            </td>
          <td style="text-align:center">
            <a href="#" @click="nextpage('tn')">
              {{ trueneg }}
            </a></td>
        </tr>
      </tbody>
    </table>
    <table class="table table-bordered" v-if="statustestacc">
      <tbody>
        <tr>
          <th scope="row">Accuracy</th>
          <td style="text-align:center" colspan="2">{{((((truepos+trueneg)/(truepos+trueneg+falsepos+falseneg)))*100).toFixed(2)}}%</td>
        </tr>
        <tr>
          <th scope="row">Precision</th>
          <td style="text-align:center">{{((truepos/(truepos+falsepos))*100).toFixed(2)}}%</td>
        </tr>
        <tr>
          <th scope="row">Recall</th>
          <td style="text-align:center">{{(truepos/(truepos+falseneg)*100).toFixed(2)}}%</td>
        </tr>
        <tr>
          <th scope="row">Specificity</th>
          <td style="text-align:center">{{(trueneg/(trueneg+falsepos)*100).toFixed(2)}}%</td>
        </tr>
        <tr>
          <th scope="row">F1-Score</th>
          <td style="text-align:center">{{(2*[((truepos/(truepos+falsepos))*(truepos/(truepos+falseneg)))/((truepos/(truepos+falsepos))+(truepos/(truepos+falseneg)))]*100).toFixed(2)}}%</td>
        </tr>
      </tbody>
    </table>

    <!-- <div v-if="statustest">
    Recall <br>
    เกินจริง : {{recall.true}} % <br>
    ไม่เกินจริง : {{recall.false}} % <br>
    Precision  <br>
    เกินจริง : {{precision.true}} %<br>
    ไม่เกินจริง : {{precision.false}} %<br>
    Accuracy {{accuracy}} %
  </div> -->
    <div class="mb-5 mt-5" v-if="statustest">
      <div style="text-align: right">
      <vue-excel-xlsx
      class="btn btn-success"
                id="excel"
                :data="listexcel"
                :columns="columns"
                :file-name="filename"
                :file-type="'xlsx'"
                :sheet-name="'sheetname'"
              >
              <i class="fa fa-file-excel"></i>  Export Excel
              </vue-excel-xlsx>&nbsp;
    </div>
      <table class="table table-bordered mt-3 mb-5" v-if="statustest">
        <tbody>
          <tr>
            <th style="background-color: #ffb454; vertical-align: top">
              ลำดับของเว็บไซต์
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              รายละเอียดสินค้า
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              officer1
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              officer2
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              officer3
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              answer
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              label
            </th>
            <th style="background-color: #ffb454; vertical-align: top">
              predict
            </th>
          </tr>
              <tr v-for="(k, i) in list" :key="i">
                <td style="background-color: rgb(255, 180, 84)">
                  <a :href="'/productpre/'+k.id+'/'+typebase" target="_blank"><span v-html="k.url"></span><br /><br /></a>
                </td>
                <td style="background-color: rgb(255, 180, 84)">
                  <span v-html="k.sentence"></span><br /><br />
                </td>
                <td :style="getcolor(k.one_result)">
                  <span v-if="k.one_result == 1">เกินจริง</span
                  ><span v-if="k.one_result == 2">ไม่เกินจริง</span
                  >
                </td>

                <td :style="getcolor(k.two_result)">
                  <span v-if="k.two_result == 1">เกินจริง</span
                  ><span v-if="k.two_result == 2">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.three_result)">
                  <span v-if="k.three_result == 1">เกินจริง</span
                  ><span v-if="k.three_result == 2">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.answertest)">
                  <span v-if="k.answertest == 1">เกินจริง</span
                  ><span v-if="k.answertest == 2">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.type_rulebasedId)">
                  <span v-if="k.type_rulebasedId == 1">เกินจริง</span
                  ><span v-if="k.type_rulebasedId == 2">ไม่เกินจริง</span
                  >
                </td>
                <td :style="getcolor(k.answertestfinal)">
                  <span v-if="k.answertestfinal == 1">เกินจริง</span
                  ><span v-if="k.answertestfinal == 2">ไม่เกินจริง</span
                  >
                  <span v-if="!k.answertestfinal">ไม่พบข้อความโฆษณา</span
                  >
                </td>
              </tr>
        </tbody>
      </table>
      <div v-if="list.length == 0" class="mt-5">
        <h3 style="text-align: center">ไม่พบข้อมูล</h3>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddScopus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label>ข้อความ</label>
                  <input
                    v-model="data.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
              id="closedcategory"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="DeleteScopus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบหมวด</h5>
          </div>

          <div class="modal-footer mt-3">
            <button
              type="button"
              class="btn btn-success"
              @click="deleteScopus()"
            >
              ยืนยัน
            </button>
            <button
              id="closedDeleteScopus"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KeywordService from "../services/KeywordService";
import axios from "axios";
import RuleBasedService from "../services/RuleBasedService";
import DictService from "../services/DictService";
import MapRuleBasedService from "../services/MapRuleBasedService";
import LinkService from "../services/LinkService";
import ProductsService from "../services/ProductsService";
import MapRuleBasedUserTestService from "../services/MapRuleBasedUserTestService";
import RulebasedTypesService from "../services/RulebasedTypesService";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import FDAStatusService from "../services/FDAStatusService";

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "App",
  components: {
    ClipLoader,
  },
  data() {
    return {
      color: "#5bc0de",
      size: "50px",
      loading: true,
      type: 0, 
      list: [],
      url: "",
      file: "",
      status: false,
      urlPath: "",
      title: "",
      data: {},
      pro_id: 0,
      category: [],
      pageOfItems: [],
      customLabels,
      testno: 80,
      alltest: 0,
      listtest: [],
      truepos: 0,
      falsepos: 0,
      trueneg: 0,
      falseneg: 0,
      statustest: false,
      statustestacc:false,
      recall: {},
      precision: {},
      accuracy: 0,
      rulebasetypes: [],
      confusion: {},
      typebase:1,
      expertclass:'btn btn-primary',
      rulebaseclass:'btn btn-secondary',
      filename:'รายงานผลลัพธ์การทดสอบ',
      columns: [],
      listexcel:[]
    };
  },
  methods: {
    nextpage(name){
      window.open('/productspretrain?name='+name+'&&typebase='+this.typebase, '_blank');
    },
    getcolor(id){
      var bg = 'background-color: rgb(249, 189, 187)'
if (id == 1) {
  bg = 'background-color: rgb(163, 233, 164)'
}
return bg
    },
    getrulebasedtype() {
      RulebasedTypesService.gettypes_rulebased().then((res) => {
        // console.log(res.data);
        this.rulebasetypes = res.data;
        for (let r = 0; r < this.rulebasetypes.length; r++) {
          if (this.rulebasetypes[r].id == 1) {
            this.rulebasetypes[r].name = 'ไม่พบ'
          }else{
            this.rulebasetypes[r].name = 'พบ (เข้าข่ายผิด)'
          }
          
        }
      });
    },
    savetorule_based(data) {
      // console.log(data);
      var test = {
        productId: data.id,
        userId: this.currentUser.id,
        remark: data.remark,
        answer: data.answer,
      };
      if (data.mapId == 0) {
        MapRuleBasedUserTestService.createmap_rule_based_user_test(test).then(
          () => {
            // this.getcategory();
            alert("บันทึกสำเร็จ");
          }
        );
      } else {
        MapRuleBasedUserTestService.updatemap_rule_based_user_test(
          data.mapId,
          test
        ).then(() => {
          // this.getcategory();
          alert("บันทึกสำเร็จ");
        });
      }
    },
    getCountryByCode(code, data) {
      return data.filter(function (data) {
        return data.type_rulebasedId == code;
      });
    },
    // getCountryByCode(code, data) {
    //   var datalist = 0
    //   for (let d = 0; d < data.length; d++) {
    //     console.log(data[d]);
    //     if (data[d].type_rulebasedId == code) {
    //       datalist=datalist+1
    //     }
    //   }
    //   return datalist
    // },
    gettp() {
      // console.log(this.listtest);
      // var positive = this.getCountryByCode(1, this.listtest);
      // var negative = this.getCountryByCode(2, this.listtest);
      // console.log(positive);
      // console.log(negative);
      FDAStatusService.deleteexperttrain(this.typebase).then(()=>{
      this.truepos = 0;
      this.falsepos = 0;
      this.trueneg = 0;
      this.falseneg = 0;
      for (let l = 0; l < this.listtest.length; l++) {
        if (this.listtest[l].answertest == 1) {
          if (this.listtest[l].answertestfinal == 1) {
          this.truepos = this.truepos + 1;
          // console.log(this.listtest[l]);
          var experttp= {
            product_token_id:this.listtest[l].id,
            type:'tp',
            rulebasetype:this.typebase
          }
          FDAStatusService.createexperttrain(experttp).then(()=>{
            
          })
        } else {
          // console.log(this.listtest[l]);
          this.falseneg = this.falseneg + 1;
          // console.log(this.listtest[l]);
          var expertfn= {
            product_token_id:this.listtest[l].id,
            type:'fn',
            rulebasetype:this.typebase
          }
          FDAStatusService.createexperttrain(expertfn).then(()=>{
            
          })
        }
        }else if (this.listtest[l].answertest == 2){
          if (this.listtest[l].answertestfinal == 2) {
           
          var experttn= {
            product_token_id:this.listtest[l].id,
            type:'tn',
            rulebasetype:this.typebase
          }
          FDAStatusService.createexperttrain(experttn).then(()=>{
            
          })
          this.trueneg = this.trueneg + 1;
        } else {
          this.falsepos = this.falsepos + 1;
          var expertfp= {
            product_token_id:this.listtest[l].id,
            type:'fp',
            rulebasetype:this.typebase
          }
          FDAStatusService.createexperttrain(expertfp).then(()=>{
            
          })
        }
        }
        
      }
//       for (let t = 0; t < positive.length; t++) {
// console.log(positive[t]);
//         if (positive[t].statustestid == 1) {
//           this.truepos = this.truepos + 1;
//         } else {
//           this.falsepos = this.falsepos + 1;
//         }
//       }
//       for (let n = 0; n < negative.length; n++) {
//         if (negative[n].statustestid == 2) {
//           this.falseneg = this.falseneg + 1;
//         } else {
//           this.trueneg = this.trueneg + 1;
//         }
//       }
      // this.truepos = 43
      // this.falsepos = 7
      // this.trueneg = 3
      // // this.falseneg = 47
      //  this.recall.true = (
      //   (this.truepos / (this.truepos + this.falsepos)) *
      //   100
      // ).toFixed(2);
      // // 51/51+12
      // // TP/(TP+FN)
      // this.recall.false = (
      //   (this.falseneg / (this.falseneg + this.trueneg)) *
      //   100
      // ).toFixed(2);
      // // (51+80)/17
      // // TP/(TP+FP)
      // this.precision.true = (
      //   (this.truepos / (this.truepos + this.trueneg)) *
      //   100
      // ).toFixed(2);
      // this.precision.false = (
      //   (this.falseneg / (this.falseneg + this.falsepos)) *
      //   100
      // ).toFixed(2);
      // this.accuracy = (
      //   ((this.truepos + this.falseneg) /
      //     (this.truepos + this.falsepos + this.trueneg + this.falseneg)) *
      //   100
      // ).toFixed(2);
      // console.log(this.truepos,this.falsepos);
      // console.log(this.trueneg,this.falseneg);

//       this.confusion.accuracy = (
//         ((this.truepos + this.trueneg) /
//           (this.truepos + this.trueneg + this.falsepos + this.falseneg)) *
//         100
//       ).toFixed(2);


//       this.confusion.precision = 
//        ( (this.truepos / (this.truepos + this.falsepos)) *
//         100).toFixed(2);

//         this.confusion.sensitivity = 
//         (this.truepos / (this.truepos + this.falseneg)) 

// this.confusion.recall = 
//        ( (this.trueneg / (this.trueneg + this.falsepos)) *
//         100).toFixed(2);
// // sen = 1
// // recall = 
// var precision = (this.truepos / (this.truepos + this.falsepos))
// var recall = (this.trueneg / (this.trueneg + this.falsepos))
// console.log(precision,recall);
//         this.confusion.f1 = 
//        ((2*((precision*recall) / (precision+recall))) 
//         );

// console.log(this.confusion);
      this.loading = false;
      this.statustest = true;
      // console.log(this.currentUser.role_id);
      if (this.currentUser.role_id == 2 || this.currentUser.role_id == 6) {
        this.statustestacc = true
      }else{
        this.statustestacc = false
      }
   
    })
    },
    getstatuscheck(t, f) {
      // console.log(t,f);
      var text = "";
      if (t == f) {
        text = "ไม่เกินจริง";
      } else if (t > f) {
        text = "เกินจริง";
      } else if (f > t) {
        text = "ไม่เกินจริง";
      }
      return text;
    },
    changtype(id){
      this.loading = true
      // console.log(this.loading);
      this.statustestacc = false
this.typebase = id
if (id == 1) {
 this.expertclass='btn btn-primary',
      this.rulebaseclass='btn btn-secondary'
}else{
  this.rulebaseclass='btn btn-primary',
      this.expertclass='btn btn-secondary'
}
this.gettest()
    },
    async gettest() {
      this.filename = 'รายงานผลลัพธ์การทดสอบ'
      this.listtest = [];
      this.listexcel = []
      this.statustest = false;
      // console.log(this.list);
      this.alltest = ((this.testno / 100) * this.list.length).toFixed(0);
      // ProductsService.findAlltest(this.alltest).then(async (test) => {
      // console.log(res.data);
      // this.listtest = res.data;
      // var arr = []
      for (let l = 0; l < this.list.length; l++) {
        // var finalresult = []
        var selectpro = {
          // url: this.data.url,
          id: this.list[l].id,
          fda_status: this.currentUser.id,
        };
        
        if (this.typebase == 1) {
           // MapRuleBasedService.getevaluateexceptrulebase(selectpro).then(
         MapRuleBasedService.getproductsevaluate(selectpro).then(
          async (res) => {
            // console.log(res.data);
            // var keyword = res.data.keyword
            if (res.data.keyword != 1) {
               this.list[l].statustest = this.getstatuscheck(
                res.data.keyword[0].statustrue,
                res.data.keyword[0].statusfalse
              );
              this.list[l].sen = res.data.keyword[0].sen;
              if (
                res.data.answertest == 1
              ) {
                this.list[l].statustestid = 1;
                this.list[l].rulename = "เกินจริง";
              } else {
                this.list[l].statustestid = 2;
                this.list[l].rulename = "ไม่เกินจริง";
              }
            } else {
              this.list[l].statustest = "ไม่พบข้อความโฆษณา";
              this.list[l].statustestid = 2;
              this.list[l].sen = "ไม่พบรูปประโยค";
              this.list[l].rulename = "ไม่เกินจริง";
            }

            this.list[l].mapId = res.data.mapId;
            this.list[l].answer = res.data.answer;
            this.list[l].remark = res.data.remark;
            this.list[l].type_rulebasedId = res.data.type_rulebasedId;
            this.list[l].one_result = res.data.one_result;
            this.list[l].two_result = res.data.two_result;
            this.list[l].three_result = res.data.three_result;
            this.list[l].answertest = res.data.answertest;

  if (this.list[l].one_result == 1) {
    this.list[l].one_result_name = 'เกินจริง'
  }else if (this.list[l].one_result == 2){
    this.list[l].one_result_name = 'ไม่เกินจริง'
  }
  if (this.list[l].two_result == 1) {
    this.list[l].two_result_name = 'เกินจริง'
  }else if (this.list[l].two_result == 2){
    this.list[l].two_result_name = 'ไม่เกินจริง'
  }
  if (this.list[l].three_result == 1) {
    this.list[l].three_result_name = 'เกินจริง'
  }else if (this.list[l].three_result == 2){
    this.list[l].three_result_name = 'ไม่เกินจริง'
  }
  if (this.list[l].answertest == 1) {
    this.list[l].answertest_name = 'เกินจริง'
  }else if (this.list[l].answertest == 2){
    this.list[l].answertest_name = 'ไม่เกินจริง'
  }
  if (this.list[l].type_rulebasedId == 1) {
    this.list[l].type_rulebasedId_name = 'เกินจริง'
  }else if (this.list[l].type_rulebasedId == 2){
    this.list[l].type_rulebasedId_name = 'ไม่เกินจริง'
  }

            if (res.data.answertestfinal != null) {
              this.list[l].answertestfinal = res.data.answertestfinal;
            }else{
              // console.log(res.data);
              this.list[l].answertestfinal = 2
            }

  if (this.list[l].answertestfinal == 1) {
    this.list[l].answertestfinal_name = 'เกินจริง'
  }else if (this.list[l].answertestfinal == 2){
    this.list[l].answertestfinal_name = 'ไม่เกินจริง'
  }else if (res.data.answertestfinal == null){
    this.list[l].answertestfinal_name = 'ไม่พบข้อความโฆษณา'
  }
            // console.log(res.data.mapId);
            // await this.listtest.push(this.list[l]);
            // console.log(this.listtest.length,this.list.length);
            if (l+1 == this.list.length) {
          // console.log(arr);
          // this.listtest = arr.sort(function (a, b) {
          //     return a.url - b.url;
          //   });
//           this.listtest = this.listtest.sort((a, b) => {
//   if (a.url < b.url) {
//     return -1;
//   }
// });
//             this.listtest.sort(function(a, b){
//     return a.url - b.url;
// });
this.listtest = this.list
              
this.listexcel = this.list
this.filename += '_Expert'

console.log(this.listexcel);
              this.gettp();
            }
          }
        );
     
        }else{
          MapRuleBasedService.getevaluateexceptrulebase(selectpro).then(
        //  MapRuleBasedService.getproductsevaluate(selectpro).then(
          async (res) => {
            // console.log(res.data);
            // var keyword = res.data.keyword
            if (res.data.keyword != 1) {
               this.list[l].statustest = this.getstatuscheck(
                res.data.keyword[0].statustrue,
                res.data.keyword[0].statusfalse
              );
              this.list[l].sen = res.data.keyword[0].sen;
              if (
                res.data.answertest == 1
              ) {
                this.list[l].statustestid = 1;
                this.list[l].rulename = "เกินจริง";
              } else {
                this.list[l].statustestid = 2;
                this.list[l].rulename = "ไม่เกินจริง";
              }
            } else {
              this.list[l].statustest = "ไม่พบข้อความโฆษณา";
              this.list[l].statustestid = 2;
              this.list[l].sen = "ไม่พบรูปประโยค";
              this.list[l].rulename = "ไม่เกินจริง";
            }

            this.list[l].mapId = res.data.mapId;
            this.list[l].answer = res.data.answer;
            this.list[l].remark = res.data.remark;
            this.list[l].type_rulebasedId = res.data.type_rulebasedId;
            this.list[l].one_result = res.data.one_result;
            this.list[l].two_result = res.data.two_result;
            this.list[l].three_result = res.data.three_result;
            this.list[l].answertest = res.data.answertest;
            if (res.data.answertestfinal) {
              this.list[l].answertestfinal = res.data.answertestfinal;
            }else{
              // console.log(res.data);
              this.list[l].answertestfinal = 2
            }

  if (this.list[l].one_result == 1) {
    this.list[l].one_result_name = 'เกินจริง'
  }else if (this.list[l].one_result == 2){
    this.list[l].one_result_name = 'ไม่เกินจริง'
  }
  if (this.list[l].two_result == 1) {
    this.list[l].two_result_name = 'เกินจริง'
  }else if (this.list[l].two_result == 2){
    this.list[l].two_result_name = 'ไม่เกินจริง'
  }
  if (this.list[l].three_result == 1) {
    this.list[l].three_result_name = 'เกินจริง'
  }else if (this.list[l].three_result == 2){
    this.list[l].three_result_name = 'ไม่เกินจริง'
  }
  if (this.list[l].answertest == 1) {
    this.list[l].answertest_name = 'เกินจริง'
  }else if (this.list[l].answertest == 2){
    this.list[l].answertest_name = 'ไม่เกินจริง'
  }
  if (this.list[l].type_rulebasedId == 1) {
    this.list[l].type_rulebasedId_name = 'เกินจริง'
  }else if (this.list[l].type_rulebasedId == 2){
    this.list[l].type_rulebasedId_name = 'ไม่เกินจริง'
  }
    if (this.list[l].answertestfinal == 1) {
    this.list[l].answertestfinal_name = 'เกินจริง'
  }else if (this.list[l].answertestfinal == 2){
    this.list[l].answertestfinal_name = 'ไม่เกินจริง'
  }else if (res.data.answertestfinal == null){
    this.list[l].answertestfinal_name = 'ไม่พบข้อความโฆษณา'
  }
            // console.log(res.data.mapId);
            // await this.listtest.push(this.list[l]);
            // console.log(this.listtest.length,this.list.length);
            if (l+1 == this.list.length) {
          // console.log(arr);
          // this.listtest = arr.sort(function (a, b) {
          //     return a.url - b.url;
          //   });
//           this.listtest = this.listtest.sort((a, b) => {
//   if (a.url < b.url) {
//     return -1;
//   }
// });
//             this.listtest.sort(function(a, b){
//     return a.url - b.url;
// });
this.listtest = this.list
this.listexcel = this.list
console.log(this.listexcel);
this.filename += '_Base'
              // console.log(this.listtest);

              this.gettp();
            }
          }
        );
     
      
        }
       
      }
      // });
    },
    getMax(arr, prop) {
      var max;
      for (var i = 0; i < arr.length; i++) {
        if (max == null || parseInt(arr[i][prop]) > parseInt(max[prop]))
          max = arr[i];
      }
      return max;
    },
    getAllMaxArray(arr, value) {
      var array = [];
      arr.map(function (num, idx) {
        if (num.allcount == value) {
          array.push(arr[idx]);
        }
      });
      return array;
    },
    sortJSON(arr, key, asc=true) {
  return arr.sort((a, b) => {
    let x = a[key];
    let y = b[key];
    if (asc) { return ((x < y) ? -1 : ((x > y) ? 1 : 0)); }
    else { return ((x > y) ? -1 : ((x < y) ? 1 : 0)); }
  });
},
    async tokendata(list) {
      //console.log(list);
      if (list.keyword.length > 0) {
        var arrtest = [];
        for (let l = 0; l < list.keyword.length; l++) {
          //console.log(list.keyword[l].keyword_dict_id);
          if (list.keyword[l].keyword_dict_id) {
            var map = {
              id: list.keyword[l].id,
              url: list.keyword[l].keyword_dict_id,
              keyword_id: list.keyword[l].dict_id,
            };
            // console.log(map);
            await MapRuleBasedService.getmapproduct(map).then(async (res) => {
              // console.log(res.data);
              var maxvalue = this.getMax(res.data, "allcount");
              // console.log(maxvalue.allcount);
              var i = this.getAllMaxArray(res.data, maxvalue.allcount);
              // console.log(i);
              var bestdata = this.getMax(i, "length");
              // console.log(bestdata);
              // getAllIndexes
              // console.log(bestdata);
              var best = {
                id: list.keyword[l].id,
                sentence: bestdata,
              };
              // console.log(best);
              var rule_based_name = bestdata.name.toString();
              rule_based_name = rule_based_name.replaceAll(",", "");
              // console.log(rule_based_name);
              await axios
                .get(
                  LinkService.getpythonlink() +
                    "/loaddict?word=" +
                    rule_based_name
                )
                .then(async () => {
                  await MapRuleBasedService.getbestrulebased(best).then(
                    async (res) => {
                      // console.log(res.data);

                      var rulebased = {
                        count_rulebased: res.data.count,
                        sentence_rulebase: res.data.sentence,
                        rule_based_id: res.data.rule_based_id,
                        rule_based_name: res.data.rule_based_name,
                        map_rule_based_id: res.data.map_id,
                      };
                      // console.log(rulebased);
                      arrtest.push(rulebased);
                      // console.log(l + 1, list.keyword.length);
                      if (l + 1 == list.keyword.length) {
                        // var maxvaluetest = this.getMax(arrtest, "allcount");
                        // var i = this.getAllMaxArray(arrtest, maxvaluetest.allcount);
                        // console.log(i);
                        // var bestdata = this.getMax(i, "length");
                        // console.log(bestdata);
                      }
                    }
                  );
                });
              // list.keyword[l].rulebase = res.data;
            });
          }

          // }
        }
      }
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
      window.scrollTo(0, 0);
    },
    deleteScopus() {
      console.log(this.pro_id);
      var data = {
        status: 0,
      };
      MapRuleBasedService.deletemap_rule_based(this.pro_id, data).then(() => {
        // console.log(res.data);
        document.getElementById("closedDeleteScopus").click();
        this.getcategory();
        alert("บันทึกสำเร็จ");
      });
    },
    save() {
      console.log(this.data);
      if (this.data.name == null || this.data.cat_id == "") {
        alert("กรุณากรอกข้อความ");
      } else {
        var prodata = {
          id: this.data.id,
          name: this.data.name,
          status: 1,
        };
        console.log(prodata);
        if (this.pro_id == 0) {
          KeywordService.createkeyword(prodata).then(() => {
            DictService.createdict(prodata).then((res) => {
              // console.log(res.data );
              RuleBasedService.createdcolumnrule_based(res.data.id).then(() => {
                document.getElementById("closedcategory").click();
                this.getcategory();
                alert("บันทึกสำเร็จ");
              });
            });
          });
        } else {
          KeywordService.updatekeyword(this.pro_id, prodata).then(() => {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert("บันทึกสำเร็จ");
          });
        }
      }
    },
    token(text) {
      var tokenize = "";
      axios
        .get(LinkService.getpythonlink() + "/tokenkeyword?text=" + text)
        .then((res) => {
          // this.tokenize = res.data
          // console.log(res.data);
          tokenize = res.data;
        });
      return tokenize;
    },
    updatetoken(data, type) {
      // console.log(data);
      var tokenize = "";
      // data.name = data.name.replaceAll(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
      // data.name = data.name.replaceAll(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');

      axios
        .get(LinkService.getpythonlink() + "/tokenkeyword?text=" + data.name)
        .then((res) => {
          this.tokenize = res.data;
          // console.log(res.data);
          tokenize = res.data.replaceAll(" ", "");
          tokenize = tokenize.replaceAll(".", "");
          tokenize = tokenize.replaceAll("''", "");
          tokenize = tokenize.replaceAll(",,", ",");
          tokenize = tokenize.replaceAll("[", "");
          tokenize = tokenize.replaceAll("]", "");
          tokenize = tokenize.replaceAll("'", "");
          tokenize = tokenize.replaceAll(",", " | ");
          var tokendata = {
            name: data.name,
            token: tokenize,
          };
          // console.log(tokendata);
          // console.log(data.id);
          KeywordService.updatekeyword(data.id, tokendata).then(() => {
            // console.log(res.data);
            if (type == "update") {
              document.getElementById("closedcategory").click();
              this.getcategory();
              alert("บันทึกสำเร็จ");
            }
          });
        });
    },
    getcategory() {
      ProductsService.getproductstoken("").then(async (res) => {
        var list = res.data
        // console.log(res.data);
        // this.list = res.data;
        var trainlistyes = 100;
        var trainlisnoend = 180;
        var train = [];
        for (let ty = 50; ty < trainlistyes; ty++) {
          var lenghttrain = String(ty + 1).length;
          var count = 3;
          // console.log(lenghttrain);
          var name = "P";
          if (String(ty + 1).length < 3) {
            var cc = count - lenghttrain;
            for (let c = 0; c < cc; c++) {
              name += 0;
            }
          }
          name += ty + 1;
          train.push(name);
          //  if ('P') {
          //    const element = array[ty];
          //  }
        }
        for (let ty = 150; ty < trainlisnoend; ty++) {
          var lenghttrainno = String(ty + 1).length;
          var countno = 3;
          // console.log(lenghttrain);
          var nameno = "P";
          if (String(ty + 1).length < 3) {
            var ccno = countno - lenghttrainno;
            for (let c = 0; c < ccno; c++) {
              nameno += 0;
            }
          }
          nameno += ty + 1;
          // console.log(nameno);
          train.push(nameno);
          // train.push(name)
          //  if ('P') {
          //    const element = array[ty];
          //  }
        }
        // console.log(train);
        var arr = []
        for (let t = 0; t < train.length; t++) {
            await arr.push(list.find(({ url }) => url === train[t]));
          
          // for (let l = 0; l < res.data.length; l++) {
          //   if (res.data[l].url == train[t]) {
          //     await this.list.push(res.data[l]);
          //   }
          // }
          if (t+1 == train.length) {
            // console.log(arr);
            this.list= await arr.sort(function(a, b){
    return a.url - b.url;
});
        await this.gettest();
        // await this.createrule()
          }
        }

      });
    },
    getid(id) {
      // console.log(id);
      this.pro_id = id;
    },
    createrule(){
      // console.log(this.list);
// var list = this.list
var list = [{id:67}]
      // var finalresult = []
        for (let l = 0; l < list.length; l++) {
        var selectpro = {
          // url: this.data.url,
          id: list[l].id,
          fda_status: this.currentUser.id,
        };
         MapRuleBasedService.getrulebaseevaluate(selectpro).then(
          async (res) => {
            console.log(res.data);
            var keyword = res.data.keyword
            if (res.data.keyword != 1) {
              for (let m = 0; m < keyword.length; m++) {
              var map = {
              id: keyword[m].id,
              url: keyword[m].keyword_dict_id,
              keyword_id: keyword[m].dict_id, 
            };
            await MapRuleBasedService.getmapproducttest(map).then(async (res) => {
                // console.log(res.data,'ss');
                if (res.data.length > 0) {
                  // console.log(1);
                var maxvalue = this.getMax(res.data, "allcount");
              // console.log(maxvalue.allcount);
              var i = this.getAllMaxArray(res.data, maxvalue.allcount);
              // console.log(i);
              var bestdata = this.getMax(i, "length");
              // console.log(bestdata);
              // getAllIndexes
              // console.log(bestdata);
              var best = {
                id: keyword[m].id,
                sentence: bestdata,
              };
              // console.log(best);
              var rule_based_name = bestdata.name.toString();
              rule_based_name = rule_based_name.replaceAll(",", "");
              // console.log(rule_based_name);
              await axios
                .get(
                  LinkService.getpythonlink() +
                    "/loaddict?word=" +
                    rule_based_name
                )
                .then(async () => {
                  await MapRuleBasedService.getbestrulebased(best).then(
                    async (res) => {
                      // console.log(res.data);

                      var rulebased = {
                        count_rulebased: res.data.count,
                        sentence_rulebase: res.data.sentence,
                        rule_based_id: res.data.rule_based_id,
                        rule_based_name: res.data.rule_based_name,
                        map_rule_based_id: res.data.map_id,
                        product_token_id:list[l].id,
                        advertise_id:keyword[m].id
                      };
                      console.log(rulebased,'rulebased');
                      MapRuleBasedService.createtestresult(rulebased).then((res)=>{
                        console.log(res.data);
                      })

                    }
                  );
                });
              }else{
                // console.log(keyword[m]);
                var sentence_rulebase = keyword[m].sentence_rulebase.replaceAll('<span style=color:yellow>','')
                   sentence_rulebase = sentence_rulebase.replaceAll('<span style=color:red>','')
                     sentence_rulebase = sentence_rulebase.replaceAll('</span>','')
                    var 
count_rulebased = parseInt(keyword[m].count_rulebased)
                var rulebasedadvertise = {
                        count_rulebased: count_rulebased,
                        sentence_rulebase: sentence_rulebase,
                        rule_based_id: JSON.parse(keyword[m].rule_based_id),
                        rule_based_name: JSON.parse(keyword[m].rule_based_name),
                        map_rule_based_id: keyword[m].map_rule_based_id,
                        product_token_id:list[l].id,
                        advertise_id:keyword[m].id
                      };
                      // console.log(rulebasedadvertise);
                MapRuleBasedService.createtestresult(rulebasedadvertise).then((res)=>{
                        console.log(res.data);
                      })
              }
            });
              }
            }
          }
        );
        }  
    },
    generatecolumn() {
      this.columns = [];
      this.columns.push(
        {
          label: "ลำดับของเว็บไซต์",
          field: "url",
          align: "center",
        },
        {
          label: "รายละเอียดสินค้า",
          field: "sentence",
          align: "center",
        },
        {
          label: "officer1",
          field: "one_result_name",
        },
        {
          label: "officer2",
          field: "two_result_name",
        },
        {
          label: "officer3",
          field: "three_result_name",
        },
        {
          label: "answer",
          field: "answertest_name",
        },
        {
          label: "label",
          field: "type_rulebasedId_name",
        },
        {
          label: "predict",
          field: "answertestfinal_name",
        },
      );
    },
  },
  mounted() {
this.generatecolumn()
    this.getcategory();
    this.getrulebasedtype();
    this.statustest = false;
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0px;
}
/* .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: #CFD5EA;
} */
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #cfd5ea;
  /* color: white; */
}
.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
  background-color: #e7ebf5;
  /* color: white; */
}
</style>
