<template>
 <div class="row container-fluid mt-5 mb-5">
      <div v-html="home.feature"></div>
      <div class="col-md-4" v-for="f in feature" :key="f.id">
        <div class="img-block">
    <img :src="f.image" class="img" style="width:100px">
</div>
<div v-html="f.content" style="text-align: center;" class="mt-3 p"></div>

      </div>
      <!-- <div class="col-md-4">
        <div class="img-block">
    <img src="../assets/fda.png" class="img">
</div>
<h6 class="mt-3">ใช้เทคนิค NLP เทรนโดย<br>
ผู้เชี่ยวชาญด้านกฎหมายโฆษณาอาหาร</h6>

      </div>
      <div class="col-md-4">
        <div class="img-block">
    <img src="../assets/fda.png" class="img">
</div>
<h6 class="mt-3">เชื่อมต่อฐานข้อมูลอย.<br>
เพื่อตรวจสอบเลขสารบบอาหาร</h6>

      </div> -->
    </div>
</template>

<script>
import HomeandFeatureService from '../services/HomeandFeatureService'

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      home:{},
      feature:[]
    }
  },
  mounted() {
    this.gethome()
    this.getfeature()
  },
  methods: {
    gethome(){
      HomeandFeatureService.gethome(1).then((res)=>{
        this.home = res.data
      })
    },
    getfeature(){
      HomeandFeatureService.getfeatures().then((res)=>{
        this.feature = res.data
      })
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
    background-color: transparent;
}
.jumbotron {
  background-image: url("../assets/bgs.png");
  background-size: cover;
  border-radius: 0rem;
}
.fw-bold{
  color: white;
}
.btn-home {
    color: #fff;
    background-color: #9600E4;
    border-color: #9600E4;
}
.menutext{
  color: white !important;
}
.head{
  background-color: #2F1C4E;
}
h6{
  color: white;
  text-align: center;
}
h5{
  color: white;
}
.img-block{
  text-align: center;
  margin-top: 3rem !important;
}
.img{
  width:100px;border-radius: 10%;
}
.p{
  margin-bottom: 0rem !important;
}
</style>
