<template>
  <div>
    <!-- <div class="topnav" v-if="currentUser">
     <div>
      <a v-for="m in menu" :key="m.id" :class="m.class" :href="m.url">{{ m.name }}</a>
    </div> 
      <a class="" style="color: white;cursor: pointer;" @click="logOut()">ออกจากระบบ</a>
    </div> -->
    <nav class="navbar navbar-expand-md navbar-dark fixed-top" :style="'background-color:'+home.colormenu" v-if="currentUser">
      <a class="navbar-brand" href="/home">
      <img :src="home.logo" alt="" width="160">
    </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-for="m in menu" :key="m.id">
          <a :class="'nav-link '+m.class" :href="m.url" aria-current="page">{{ m.name }}</a>
        </li>
        
        <li class="nav-item">
          <a class="nav-link" @click="logOut()" href="#">ออกจากระบบ</a>
        </li>
        </ul>
      </div>
    </nav>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top" :style="'background-color:'+home.colormenu" v-if="!currentUser">
      <a class="navbar-brand" href="/home">
      <img src="./assets/logo-white.png" alt="" width="160">
    </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
      <form class="d-flex">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-if="!currentUser">
          <a class="nav-link menutext" href="/login">เข้าสู่ระบบ</a>
        </li>
          <!-- <li class="nav-item" v-for="m in menu" :key="m.id" :class="m.class">
          <a class="nav-link menutext" aria-current="page" :href="m.url">{{ m.name }}</a>
        </li> -->
 <li class="nav-item">
          <a class="nav-link menutext" aria-current="page" href="/about">เกี่ยวกับเรา</a>
        </li>
        <li class="nav-item">
          <a class="nav-link menutext" href="/contact">ติดต่อ</a>
        </li>
        <!-- <li v-if="currentUser" class="nav-item">
          <a class="nav-link menutext" @click="logOut()">ออกจากระบบ</a>
        </li> -->
        </ul>
            </form>
    </div>
    </nav>
    <div class="row mb-5" :style="'margin-top: 3rem;background-color:'+home.colorbackground">
      <div class="col-md-12">
        <div class="p-5 mb-4 bg-light jumbotron" :style="'background-image: url('+image+')'">
    <!-- <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="/home">
      <img src="./assets/logo-white.png" alt="" width="160">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      </ul>
      <form class="d-flex">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-if="!currentUser">
          <a class="nav-link menutext" href="/login">เข้าสู่ระบบ</a>
        </li>
 <li class="nav-item">
          <a class="nav-link menutext" aria-current="page" href="/about">เกี่ยวกับเรา</a>
        </li>
        <li class="nav-item">
          <a class="nav-link menutext" href="/contact">ติดต่อ</a>
        </li>
        </ul>
            </form>
    </div>
</nav> -->
      <div class="container-fluid py-5">
       <div v-html="home.title"></div>
       <a href="/testproduct"> <button class="btn btn-home btn-lg mt-5" :style="'height:45px;background-color:'+home.colorbutton+';border-color:'+home.colorbutton" type="button"><span v-html="home.button"></span></button></a>
      </div>
    </div>
    <div class="row container-fluid mt-3 mb-5">
       <router-view style="padding:3rem"/>
    </div>
      </div>
  
    <footer>
  <p><br><br>
</p>
</footer>
</div>
   
  </div>
</template>


<script>
import UserService from './services/UserService'
import HomeandFeatureService from './services/HomeandFeatureService'

export default {
  name: "app",
  components: {
  },
  data() {
    return {
      show: true,
      menu: [],
      home:'',
      image:''
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    gethome(){
      HomeandFeatureService.gethome(1).then((res)=>{
        this.home = res.data
        this.image = res.data.image
        // console.log(res.data);
        // console.log(this.image);
      })
    },
    login(){
      this.$router.push("/login");
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
        location.reload();
      }, 500);
      this.$router.push("/home");
    },
  },
  mounted() {
    // console.log(this.$route.name);
    this.gethome()
    if (this.$route.params.name == "map" || this.$route.name == "login" || this.$route.name == "register") {
      this.show = false;
    }
    if (this.$route.name == null) {
      this.$router.push("/home");
    }
    // this.menu.push(
    // {
    //   name:'แดชบอร์ด',
    //   path:'/',
    //   class:''
    // },
    //   {
    //   name:'ค้นหาสินค้าจากลิงก์',
    //   path:'/import',
    //   class:''
    // },
    // {
    //   name:'ค้นหาสินค้าจากเลขอย.',
    //   path:'/searchbyfda',
    //   class:''
    // },
    // {
    //   name:'รายการสินค้าที่ตรวจสอบทั้งหมด',
    //   path:'/scraping',
    //   class:''
    // },

    // // {
    // //   name:'หมวด',
    // //   path:'/category',
    // //   class:''
    // // },
    // // {
    // //   name:'รายการสินค้าที่ยกเลิกการตรวจสอบ',
    // //   path:'/scrapingdelete',
    // //   class:''
    // // },

    // {
    //   name:'Corpus',
    //   path:'/Corpus',
    //   class:''
    // },
    // {
    //   name:'keywords',
    //   path:'/keywords',
    //   class:''
    // },
    // {
    //   name:'Advertising',
    //   path:'/Advertising?id=1',
    //   class:''
    // },
    // {
    //   name:'rule based',
    //   path:'/rulebased',
    //   class:''
    // }
    // )
// console.log(this.currentUser.role_id);
    if (this.currentUser) {
      UserService.getMenubyRoleID(this.currentUser.role_id).then((res) => {
        this.menu = res.data
        // console.log(this.menu); 

        for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = ''
          if (this.menu[m].url == this.$route.path) {
            this.menu[m].class = 'active'
          }
        }
      //   this.menu.push({
      //     name:'รายการสินค้าที่ตรวจสอบทั้งหมด',
      // url:'/scraping',
      // class:''
      //   })
      })
    }else{
      this.menu.push({
      
        class: "",
name: 'เข้าสู่ระบบ',
url: '/login'
      })
      for (let m = 0; m < this.menu.length; m++) {
          this.menu[m].class = ''
          if (this.menu[m].url == this.$route.path) {
            this.menu[m].class = 'active'
          }
        }
    }



  },
};
</script>

<style>
.nav {
    background-color: transparent;
}
.jumbotron {
  background-size: cover;
  border-radius: 0rem;
}
.fw-bold{
  color: white;
}
.btn-home {
    color: #fff;
    /* background-color: #9600E4;
    border-color: #9600E4; */
}
.menutext{
  color: white !important;
}
.head{
  background-color: #2F1C4E;
}
h6{
  color: white;
  text-align: center;
}
h5{
  color: white;
}
.img-block{
  text-align: center;
  margin-top: 3rem !important;
}
.img{
  width:100px;border-radius: 10%;
}
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  }
  body {
    font-family: 'Prompt';
}
</style>