import http from "../http-common";

class FDAStatusService {
  getfda_statuss(name) {
    return http.get('/fda_status?name='+name);
  }
  getfda_status(id) {
    return http.get('/fda_status/'+id);
  }
  createfda_status(data) {
    return http.post('/fda_status', data);
  } 
  updatefda_status(id,data) {
    return http.put(`/fda_status/${id}`, data);
  }
  deletefda_status(id) {
    return http.delete(`/fda_status/${id}`);
  }
  createexperttrain(data) {
    return http.post('/experttrain', data);
  } 
  getexperttrain() {
    return http.get('/experttrain');
  } 
  deleteexperttrain(id) {
    return http.delete('/experttrain/'+id);
  } 
  
}

export default new FDAStatusService();

