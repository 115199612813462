import http from "../http-common";

class HomeandFeatureService {
  gethome(id) {
    return http.get('/home/'+id);
  }
  updatehome(id,data) {
    return http.put(`/home/${id}`, data);
  }
  getfeatures() {
    return http.get('/features/');
  }
  createfeature(data) {
    return http.post('/features', data);
  } 
  updatefeatures(id,data) {
    return http.put(`/features/${id}`, data);
  }
  deletefeature() {
    return http.delete(`/features`);
  }
}

export default new HomeandFeatureService();

