<template>
 <div style="padding:5rem">
      <h5 class="mb-5">ติดต่อเรา</h5>
     <div v-html="home.contact"></div>
     
    </div>
</template>

<script>
import HomeandFeatureService from '../services/HomeandFeatureService'

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      home:{}
    }
  },
  mounted() {
    this.gethome()

  },
  methods: {
    gethome(){
      HomeandFeatureService.gethome(1).then((res)=>{
        this.home = res.data
      })
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
    background-color: transparent;
}
.jumbotron {
  background-image: url("../assets/bgs.png");
  background-size: cover;
  border-radius: 0rem;
}
.fw-bold{
  color: white;
}
.btn-home {
    color: #fff;
    background-color: #9600E4;
    border-color: #9600E4;
}
.menutext{
  color: white !important;
}
.head{
  background-color: #2F1C4E;
}
h6{
  color: white;
  text-align: left;
}
h5{
  color: white;
}
.img-block{
  text-align: center;
  margin-top: 3rem !important;
}
.img{
  width:100px;border-radius: 10%;
}
</style>
