<template>
 <div style="padding:5rem">
  <div style="background-color: #198754!important;">
    <div style="padding:2rem">
    <h5>โลโก้</h5>
    <img :src="logo" v-if="logo" style="width:400px">
                  <input id="file" class="form-control mt-3" type="file" accept="image/*" @change="onFileChangeLogo"  />
            
     </div>
    <div style="padding:2rem">
    <h5>ภาพพื้นหลัง</h5>
    <img :src="image" v-if="image" style="width:400px">
                  <input id="file" class="form-control mt-3" type="file" accept="image/*" @change="onFileChange"  />
            
     </div>
     <div style="padding:2rem">
      <h5>สีเมนู</h5>
     <input type="color" id="colormenu" name="head" :value="home.colormenu" @change="getcolormenu"/>
</div>
<div style="padding:2rem">
      <h5>สีปุ่มทดสอบ</h5>
     <input type="color" id="colorbutton" name="head" :value="home.colorbutton" @change="getcolorbutton"/>
</div>
<div style="padding:2rem">
      <h5>สีพื้นหลัง</h5>
     <input type="color" id="colorbackground" name="head" :value="home.colorbackground" @change="getcolorbackground"/>
</div>
    <div style="padding:2rem">
    <h5>หัวข้อ</h5>
  <vue-editor              
              use-custom-image-handler
              v-model="home.title"
            />
            
     </div>
    
     <div style="padding:2rem">
    <h5>ชื่อปุ่ม</h5>
  <vue-editor              
              use-custom-image-handler
              v-model="home.button"
            />
            
     </div>
     <div style="padding:2rem">
    <h5>คุณสมบัติ</h5>
  <vue-editor              
              use-custom-image-handler
              v-model="home.feature"
            />
            
     </div>
     <div style="padding:2rem">
    <h5>เกี่ยวกับเรา</h5>
  <vue-editor              
              use-custom-image-handler
              v-model="home.about"
            />
            
     </div>
     <div style="padding:2rem">
    <h5>ติดต่อ</h5>
  <vue-editor              
              use-custom-image-handler
              v-model="home.contact"
            />
            
     </div>
     <div style="text-align:center">
      <button 
              type="button"
              class="btn btn-info btn-md mt-3 mb-5"
              @click="save()"
            >
              บันทึก
            </button>
     </div>
     
    </div>

    <div class="mt-5" style="background-color: #198754!important;">
    <div style="padding:2rem">
      <div style="text-align: right;">
                    <button
                    @click="addfeature()"
                      type="submit"
                      class="mb-3 btn btn-primary"
                    >
                      <i class="fa fa-plus" aria-hidden="true">
                        เพิ่มคุณสมบัติ</i
                      >
                    </button>
                  </div>
      <h5>รายละเอียดคุณสมบัติของ SAVEST AI</h5>
      <div v-for="f in features" :key="f.id">
        <div style="text-align: right;" class="mt-5">
                    <button
                    @click="removefeature(f.id)"
                      type="submit"
                      class="mb-3 btn btn-danger"
                    >
                      <i class="fa fa-minus" aria-hidden="true">
                        ลบคุณสมบัติ {{f.no}}</i
                      >
                    </button>
                  </div>
        <h5>ภาพคุณสมบัติ {{f.no}}</h5>
       
    <img :src="f.image" v-if="f.image" style="width:200px">
                  <input @click="getimagefea(f.no)" class="form-control mt-3" type="file" accept="image/*" @change="onFileChangeFeature"  />
                  <h5 class="mt-3"></h5>
  <vue-editor              
              use-custom-image-handler
              v-model="f.content"
            />
            <hr>
      </div>
   
     </div>
    
    
            
    
     <div style="text-align:center">
      <button 
              type="button"
              class="btn btn-info btn-md mt-3 mb-5"
              @click="savefeature()"
            >
              บันทึก
            </button>
     </div>
     
    </div>
    </div>
</template>

<script>
import LinkService from "../services/LinkService";
import axios from 'axios';
import HomeandFeatureService from "../services/HomeandFeatureService";

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      home:{},
      image:'',
      features:[],
      status:0,
      imageid:'',
      logo:''
    }
  },
  mounted() {
    HomeandFeatureService.gethome(1).then((res)=>{
      this.home = res.data
      console.log(res.data);
      this.image = res.data.image
      this.logo = res.data.logo
    })
    this.getfeatures()
  },
  methods: {
    addfeature(){
      this.features.push({
        no:parseInt(this.features.length)+1,
        image:'',
        content:''
      })
    },
    removefeature(id){
      var list =[]
for (let f = 0; f < this.features.length; f++) {
  if (this.features[f].id != id) {
    list.push(this.features[f])
  }
  if (f+1 == this.features.length) {
    this.features = list
  }
  
}
    },
getfeatures(){
HomeandFeatureService.getfeatures().then((res)=>{
  this.features = res.data
  console.log(res.data);
})
},
getcolormenu(){
  // console.log(document.getElementById("colormenu").value);
  this.home.colormenu = document.getElementById("colormenu").value
  console.log(this.home.colormenu);
// console.log(evt);
},
getcolorbutton(){
  // console.log(document.getElementById("colormenu").value);
  this.home.colorbutton = document.getElementById("colorbutton").value
  console.log(this.home.colorbutton);
// console.log(evt);
},
getcolorbackground(){
  // console.log(document.getElementById("colormenu").value);
  this.home.colorbackground = document.getElementById("colorbackground").value
  console.log(this.home.colorbackground);
// console.log(evt);
},
onFileChangeLogo(evt) {
  const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImageLogo(files[0]);
      // this.createBase64Image(selectedImage);     
    },
    onUploadFileImageLogo(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.getpythonlink()
      // console.log(this.data.type);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
const str = selectedFile.name;
let m;
// console.log(selectedFile.type);
let checkthai = ''
let name = selectedFile.name
let typefile = (selectedFile.type).split('/')
while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach(() => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
checkthai = 1
    });
}
if (checkthai == 1) {
  name = this.makeid(5)+'.'+typefile[1]
}
// console.log(name);
var http = link + '/uploadimg?name=' + name +'&&path=/logo';
      axios
        .post(http, formData)
        .then(() => {
          this.home.logo = link + '/uploads/logo/'+name;
          this.logo =  link + '/uploads/logo/'+name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChange(evt) {
  const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
      // this.createBase64Image(selectedImage);     
    },
    onUploadFileImage(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.getpythonlink()
      // console.log(this.data.type);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
const str = selectedFile.name;
let m;
// console.log(selectedFile.type);
let checkthai = ''
let name = selectedFile.name
let typefile = (selectedFile.type).split('/')
while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach(() => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
checkthai = 1
    });
}
if (checkthai == 1) {
  name = this.makeid(5)+'.'+typefile[1]
}
// console.log(name);
var http = link + '/uploadimg?name=' + name +'&&path=/home';
      axios
        .post(http, formData)
        .then(() => {
          this.home.image = link + '/uploads/home/'+name;
          this.image =  link + '/uploads/home/'+name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getimagefea(no){
      this.imageid = no;
    },
    onFileChangeFeature(evt) {
console.log(this.imageid);
  const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImageFeature(files[0]);
      // this.createBase64Image(selectedImage);     
    },
    onUploadFileImageFeature(selectedFile) {
      
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkService.getpythonlink()
      // console.log(this.data.type);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
const str = selectedFile.name;
let m;
// console.log(selectedFile.type);
let checkthai = ''
let name = selectedFile.name
let typefile = (selectedFile.type).split('/')
while ((m = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
        regex.lastIndex++;
    }
    
    // The result can be accessed through the `m`-variable.
    m.forEach(() => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
checkthai = 1
    });
}
if (checkthai == 1) {
  name = this.makeid(5)+'.'+typefile[1]
}
// console.log(name);
var http = link + '/uploadimg?name=' + name +'&&path=/feature';
      axios
        .post(http, formData)
        .then(() => {
          var list = this.features
          for (let f = 0; f < list.length; f++) {
            console.log(list[f].no , this.imageid);
            if (list[f].no == this.imageid) {
              list[f].image = link + '/uploads/feature/'+name
            }
            if (f+1 == list.length) {
              console.log(list);
              this.features = list
            }
          }
          // console.log(this.features);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save(){
      if (this.home.title == '' || this.home.title == null) {
       alert('กรุณากรอกหัวข้อ') 
      }else if (this.home.button == '' || this.home.button == null) {
       alert('กรุณากรอกชื่อปุ่ม') 
      }else if (this.home.about == '' || this.home.about == null) {
       alert('กรุณากรอกเกี่ยวกับเรา') 
      }else if (this.home.contact == '' || this.home.contact == null) {
       alert('กรุณากรอกติดต่อเรา') 
      }
      else if (this.home.feature == '' || this.home.feature == null) {
       alert('กรุณากรอกคุณสมบัติ') 
      }else if (this.image == '' || this.image == null) {
       alert('กรุณาอัพโหลดรูปภาพพื้นหลัง') 
      }
      else if (this.logo == '' || this.logo == null) {
       alert('กรุณาอัพโหลด logo') 
      }else if (this.home.feature == '' || this.home.feature == null) {
       alert('กรุณากรอกคุณสมบัติ') 
      }else if (this.home.colorbackground == '' || this.home.colorbackground == null) {
       alert('กรุณาเลือกสีพื้นหลัง') 
      }else if (this.home.colorbutton == '' || this.home.colorbutton == null) {
       alert('กรุณาเลือกสีปุ่มทดสอบ') 
      }else if (this.home.colormenu == '' || this.home.colormenu == null) {
       alert('กรุณาเลือกสีเมนู') 
      }
      else{
        var data = {
          title:this.home.title,
          button:this.home.button,
          about:this.home.about,
          contact:this.home.contact,
          feature:this.home.feature,
          image:this.image,
          logo:this.logo,
          colorbackground:this.home.colorbackground,
          colorbutton:this.home.colorbutton,
          colormenu:this.home.colormenu,
        }
        HomeandFeatureService.updatehome(1,data).then(()=>{
          alert('บันทึกสำเร็จ')
        })
      }
    },
    savefeature(){
      this.status = 0
      for (let f = 0; f < this.features.length; f++) {
        if (this.features[f].image == null || this.features[f].image == '') {
          alert('กรุณาอัพโหลดรูปภาพให้ครบ')
          this.status=1
          break;
        }else if (this.features[f].content == null || this.features[f].content == '') {
          alert('กรุณากรอกรายละเอียดคุณสมับติ')
          this.status=1
          break;
        }else{
          this.status = 0
        }
        if (f+1 == this.features.length) {
          if (this.status == 0) {
            HomeandFeatureService.deletefeature().then(()=>{
              for (let ff = 0; ff < this.features.length; ff++) {
            var feature ={
              image:this.features[ff].image,
              content:this.features[ff].content,
              no:ff+1
            }
            HomeandFeatureService.createfeature(feature).then(()=>{
if (ff+1 == this.features.length) {
  alert('บันทึกสำเร็จ')
}
            })
              
            }
            })
            
          }
        }
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
    background-color: transparent;
}
.jumbotron {
  background-image: url("../assets/bgs.png");
  background-size: cover;
  border-radius: 0rem;
}
.fw-bold{
  color: white;
}
.btn-home {
    color: #fff;
    background-color: #9600E4;
    border-color: #9600E4;
}
.menutext{
  color: white !important;
}
.head{
  background-color: #2F1C4E;
}
h6{
  color: white;
  text-align: left;
}
h5{
  color: white;
}
.img-block{
  text-align: center;
  margin-top: 3rem !important;
}
.img{
  width:100px;border-radius: 10%;
}
</style>
