<template>
  <div class="container mt-5" style="background-color:white">
    <!-- <div style="text-align:right"> <button @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddScopus"
           type="submit" class="mb-3 btn btn-success">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button></div> -->
    <div class="mb-5">
      <table class="table table-striped table-bordered" style="background-color:#4472C4" v-if="list.length > 0" width="100%">
        <thead>
          <tr>
            <th scope="col" style="text-align:center">ลำดับของเว็บไซต์</th>
            <th scope="col">ประเภทผลิตภัณฑ์</th>
            <th scope="col">รายละเอียดสินค้า</th>
            
            <th scope="col" style="text-align:center">เข้าสู่ระบบการเทรน</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list" :key="i+1">
            <td style="width:20%">{{ l.url }}</td>
            <td style="width:20%">{{ l.typename }}</td>
            <td style="width:40%">
              <img :src="l.img_path" style="width:50%"><br>
              {{ l.sentence }}</td>
          <td style="text-align:center"><a :href="'/product/'+l.id"> <button
                type="button"
                style="background-color: #4472C4;color:white;"
                class="btn"
              >เทรน
               </button
            > </a></td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div> -->
      <div v-if="list.length == 0" class="mt-5">
<h3 style="text-align:center">ไม่พบข้อมูล</h3>
      </div>
    </div>
  <!-- Modal -->
  <div
      class="modal fade"
      id="AddScopus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label>ข้อความ</label>
                  <input
                    v-model="data.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closedcategory"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  <div
      class="modal fade"
      id="DeleteScopus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบหมวด</h5>
            
          </div>
         
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="deleteScopus()">
              ยืนยัน
            </button>
            <button
            id="closedDeleteScopus"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import KeywordService from '../services/KeywordService'
import axios from 'axios';
import RuleBasedService from '../services/RuleBasedService'
import DictService from '../services/DictService'
import MapRuleBasedService from '../services/MapRuleBasedService'
import LinkService from '../services/LinkService'
import ProductsService from '../services/ProductsService'

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "App",
  components: {},
  data() {
    return {
      type: 0,
      list: [],
      url:'',
      file:'',
      status:false,
      urlPath:'',
      title:'',
      data:{},
      pro_id:0,
      category:[],
      pageOfItems: [],
      customLabels,
    };
  },
  methods: {
     onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
      window.scrollTo(0,0);
    },
    deleteScopus(){
      console.log(this.pro_id);
      var data ={
        status: 0
      }
      MapRuleBasedService.deletemap_rule_based(this.pro_id,data).then((res)=>{
        console.log(res.data);
        document.getElementById("closedDeleteScopus").click();
        this.getcategory();
        alert('บันทึกสำเร็จ')
      })
    },
    save() {
      // console.log(this.data);
      if (this.data.name == null || this.data.cat_id == "") {
        alert("กรุณากรอกข้อความ");
      } else {
        var prodata = {
          id:this.data.id,
          name: this.data.name,
          status:1,
        };
        // console.log(prodata); 
        if (this.pro_id == 0) {
          KeywordService.createkeyword(prodata).then(() => {
          DictService.createdict(prodata).then((res) => {
            // console.log(res.data );
            RuleBasedService.createdcolumnrule_based(res.data.id).then(() => {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          });
        });
      });
        } else {
          KeywordService.updatekeyword(this.pro_id,prodata).then(() => {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          });
            }
      }
    },
    token(text){
      var tokenize = ''
      axios.get(LinkService.getpythonlink()+'/tokenkeyword?text=' + text).then((res) => {
        // this.tokenize = res.data
        // console.log(res.data);
        tokenize = res.data
      });
      return tokenize
    },
    updatetoken(data,type){
      // console.log(data);
      var tokenize= ''
      // data.name = data.name.replaceAll(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
      // data.name = data.name.replaceAll(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');

      axios.get(LinkService.getpythonlink()+'/tokenkeyword?text=' + data.name).then((res) => {
        this.tokenize = res.data
        // console.log(res.data);
        tokenize = res.data.replaceAll(" ",'')
        tokenize = tokenize.replaceAll(".",'')
        tokenize = tokenize.replaceAll("''",'')
        tokenize = tokenize.replaceAll(",,",',')
        tokenize = tokenize.replaceAll('[','')
        tokenize = tokenize.replaceAll(']','')
        tokenize = tokenize.replaceAll("'",'')
        tokenize = tokenize.replaceAll(",",' | ')
      var tokendata = {
        name:data.name,
        token: tokenize,
        };
        // console.log(tokendata);
        // console.log(data.id);
        KeywordService.updatekeyword(data.id, tokendata).then(() => {
          // console.log(res.data);
          if (type == 'update') {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          }
        });
      });
    },
    getAllIndexes(arr, val) {
      var indexes = [],
        i = -1;
      while ((i = arr.indexOf(val, i + 1)) != -1) {
        indexes.push(i);
      }
      return indexes;
    },
    findfda(data) {
    // console.log(data);
      var text = ['หมายเลขใบอนุญาต/อย.','เลขจดแจ้ง','เลขอย.','อย.']
      var end = ['จำนวนสินค้า','ส่วนประกอบ','น้ำหนัก','วันหมดอายุ','คลังสินค้าโปรโมชั่น','ปริมาณ','ประเทศต้นกำเนิดสินค้า','ส่วนผสมของผลิตภัณฑ์','ประเภทอาหาร','________________________ ']
      var findfda = data
      for (let t = 0; t < end.length; t++) {
        // console.log(findfda.indexOf(end[t]));
        // console.log(findfda.indexOf(text[0]));
        // console.log(findfda.indexOf(end[t]));
        if (findfda.indexOf(end[t]) != -1) {
          // console.log(end[t]);
          findfda = findfda.substring(findfda.indexOf(text[0]),findfda.indexOf(end[t]));
        }
         

      }
      // console.log(findfda);

      findfda = findfda.replaceAll("หมายเลขใบอนุญาต/อย.", "");
      findfda = findfda.replaceAll("เลขจดแจ้ง", "");
      findfda = findfda.replaceAll("เลขอย.", "");
      findfda = findfda.replaceAll("อย.", "");

      findfda = findfda.replaceAll("-", "");
      findfda = findfda.replaceAll("–", "");
      // console.log(findfda);
      // var regex = /\d+/g;
      // var matches = findfda.match(regex);  // creates array from matches
      // console.log(matches[0]);
      return findfda
    },
    // findfda(data) {
    //   var text = ["หมายเลขใบอนุญาต","หมายเลขใบอนุญาต/อย.","อย."];
    //   // var end = ['']
    //   var findfda = data;
    //   for (let t = 0; t < text.length; t++) {
    //     findfda = findfda.substring(findfda.indexOf(text[t]), data.length);
    //     var endindex = this.getAllIndexes(findfda, " ");
    //     findfda = findfda.substring(findfda.indexOf(text[t]), endindex[1]);
    //   }
    //   // console.log(findfda);

    //   for (let v = 0; v < text.length; v++) {
    //   findfda = findfda.replaceAll(text[v], "");
    //   }
    //   // findfda = findfda.replaceAll(/[^\d.-]/g, '');
    //   findfda = findfda.replaceAll("-", "");
    //   findfda = findfda.replaceAll("–", "");
    //   findfda = findfda.replaceAll(" ", "");
    //   // console.log(findfda);
    //   // var regex = /\d+/g;
    //   // var matches = findfda.match(regex);  // creates array from matches
    //   // console.log(matches[0]);
    //   return findfda;
    // },
    getfda(content){
        var tt = content.split(' ')

    // console.log(tt);
    var arr = []
    for (let l = 0; l < tt.length; l++) {
      var findfda = tt[l].replaceAll('-', '');
      findfda = findfda.replaceAll("–", "");
      findfda = findfda.replaceAll(".", "");
    findfda = findfda.replaceAll(/[^\d.-]/g, '');
    // console.log(findfda);
    if (findfda) {
      if (findfda.length >= 13) {
      arr.push(findfda)
      }
    }
      
    }
    // console.log(arr);
    return arr;
    },
    
    getcategory(){
      ProductsService.getproductstoken('').then((res)=>{
        // console.log(res.data);
        // this.list = res.data


var trainlistyes = 50
var trainlisnoend = 150
var train = []
for (let ty = 0; ty < trainlistyes; ty++) {
  var lenghttrain = (String((ty+1)).length)
  var count = 3
  // console.log(lenghttrain);
  var name = 'P'
  if ((String((ty+1)).length) <3) {
    var cc = count-lenghttrain
    for (let c = 0; c < cc; c++) {
      name+=0
    }
  }
  name+=ty+1
  train.push(name)
//  if ('P') {
//    const element = array[ty];
//  }
  
}
for (let ty = 100; ty < trainlisnoend; ty++) {
  var lenghttrainno = (String((ty+1)).length)
  var countno = 3
  // console.log(lenghttrain);
  var nameno = 'P'
  if ((String((ty+1)).length) <3) {
    var ccno = countno-lenghttrainno
    for (let c = 0; c < ccno; c++) {
      nameno+=0
    }
  }
  nameno+=ty+1
  train.push(nameno)
  // train.push(name)
//  if ('P') {
//    const element = array[ty];
//  }
  
}
// console.log(train);
for (let t = 0; t < train.length; t++) {
  for (let l = 0; l < res.data.length; l++) {
    if (res.data[l].url == train[t]) {
       this.list.push(res.data[l])
    }
  }
}
// console.log(this.list);
        // for (let l = 0; l < this.list.length; l++) {
        //   console.log(this.list[l].id);
        //   var fda = this.findfda(this.list[l].sentencefull)
        //   // console.log(res.data.sentencefull);
        //   if (fda) {
        //     fdaval = fda
        //   }else{
        //     fda = this.getfda(this.list[l].sentencefull)
        //   var fdaval = ''
        //   for (let f = 0; f < 1; f++) {
        //     var ff = String(fda[f])
        //     var strff=''
        //     // console.log(ff.length);
        //     if (ff.length > 13) {
        //       for (let f = 0; f < ff.length; f++) {
        //       if (f<=12) {
        //         strff+=ff[f]
        //       }
        //     }
        //     fdaval = strff
            
        //     }else{
        //       fdaval = fda[0]
        //     }
        //   }
        // }
        // // console.log(fdaval);
        // var thenum = fdaval.replace(/^\D+/g, '');
        // if (String(thenum).length > 20) {
        //   thenum = ''
        //     }
        // console.log(thenum);
        //   // var data = {
        //   //   fda :thenum
        //   // }
        //   // ProductsService.updatefda(this.list[l].id,data).then(()=>{

        //   // })
          
        // }
      //   var selectpro = {
      //   // url: this.data.url,
      //   id: 118,
      // };
        // MapRuleBasedService.getproduct_token(selectpro).then((res)=>{
        //   var fda = this.findfda(res.data.sentencefull)
        //   // console.log(res.data.sentencefull);
        //   console.log(fda);
        //   if (!fda) {
            
        //   }
          // var fdaval = ''
          // for (let f = 0; f < fda.length; f++) {
          //   var ff = String(fda[f])
          //   var strff=''
          //   // console.log(ff.length);
          //   if (ff.length > 13) {
          //     for (let f = 0; f < ff.length; f++) {
          //     if (f<=12) {
          //       strff+=ff[f]
          //     }
          //   }
          //   fdaval = strff
          //   }else{
          //     fdaval = fda[0]
          //   }
          //   console.log(fdaval);
            
          // }
        // })
      })
    },
    getid(id) {
      // console.log(id);
      this.pro_id = id;
    },
  },
  mounted() {
    this.getcategory()
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0px;
}
/* .table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: #CFD5EA;
} */
.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: #CFD5EA;
  /* color: white; */
}
.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: #E7EBF5;
  /* color: white; */
}
</style>
