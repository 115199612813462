<template>
  <div class="container mt-5" style="background-color:white">
    <div style="text-align:right"> <button
          data-bs-toggle="modal"
          data-bs-target="#AddRulebased"
           type="submit" class="mb-3 btn btn-success">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button></div>
    <div class="mb-5">
      <table class="table" v-if="list.length > 0" width="100%">
        <thead>
          <tr>
            <th scope="col">#</th>
            <!-- <th scope="col" style="width:10%">หมวดหมู่</th> -->
            <th scope="col">ประโยค</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list" :key="i+1">
            <td style="width:5%">{{ i + 1 }}</td>
            <!-- <td style="width:15%">{{ l.category }}</td> -->
             <td>
        <tr style="border: none;">
            <td style="border: none;padding: 0px;" v-for="(a,ai) in l.dict" :key="ai">{{ a.dict_name }} &nbsp;&nbsp;&nbsp;
            </td> 
        </tr>
        <tr style="border: none;">
            <td style="border: none;padding: 0px;color:blue;" v-for="(k,ki) in l.dict" :key="ki">{{ k.dict_id }}&nbsp;&nbsp;
            </td>&nbsp;
        </tr>
            </td>
           
             <!-- <td :style="l.bg+';width:80%'"><span v-for="(a,i) in l.sen" :key="i">{{ a.name }}&nbsp;</span><br/>
              <span v-for="(d) in l.dict_id" :key="d">{{ d }}&nbsp;</span> </td> -->
            <td :style="l.bg+';width:10%'"><span v-if="l.answer == 1">เกินจริง</span><span v-if="l.answer == 9">ไม่เกินจริง</span><span v-if="!l.answer"></span></td>
            <!-- <td> -->
            <!-- <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddRulebased"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp; -->
            <!-- <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteScopus"
              >
                <i class="fa fa-trash"></i></button
            ></a> -->
          <!-- </td> -->
          </tr>
        </tbody>
      </table>
      <!-- <div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
          ></jw-pagination>
        </div>
      </div> -->
      <div v-if="list.length == 0" class="mt-5">
<h3 style="text-align:center">ไม่พบข้อมูล</h3>
      </div>
    </div>
  <!-- Modal -->
  <div
      class="modal fade"
      id="AddRulebased"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label>ข้อความ</label>
                  <input
                    v-model="data.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closedcategory"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  <div
      class="modal fade"
      id="DeleteScopus"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบหมวด</h5>
            
          </div>
         
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="deleteScopus()">
              ยืนยัน
            </button>
            <button
            id="closedDeleteScopus"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import KeywordService from '../services/KeywordService'
import axios from 'axios';
import RuleBasedService from '../services/RuleBasedService'
import DictService from '../services/DictService'
import MapRuleBasedService from '../services/MapRuleBasedService'
import LinkService from '../services/LinkService'

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "App",
  components: {},
  data() {
    return {
      type: 0,
      list: [],
      url:'',
      file:'',
      status:false,
      urlPath:'',
      title:'',
      data:{},
      pro_id:0,
      category:[],
      pageOfItems: [],
      customLabels,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
     onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
      window.scrollTo(0,0);
    },
    deleteScopus(){
      console.log(this.pro_id);
      var data ={
        status: 0
      }
      MapRuleBasedService.deletemap_rule_based(this.pro_id,data).then((res)=>{
        console.log(res.data);
        document.getElementById("closedDeleteScopus").click();
        this.getcategory();
        alert('บันทึกสำเร็จ')
      })
    },
    token(text){
      var tokenize = ''
      axios.get(LinkService.getpythonlink()+'/token?text=' + text).then((res) => {
        // this.tokenize = res.data
        // console.log(res.data);
         tokenize = res.data
      });
      return tokenize
    },
    async save() {
      // console.log(this.data);
      var datas = {}
      var token = ''
      if (this.data.name == null || this.data.name == "") {
        alert("กรุณากรอกข้อความ");
      } else {
        axios.get(LinkService.getpythonlink()+'/token?text=' + this.data.name).then(async (res) => {
        // this.tokenize = res.data
        // console.log(res.data);

        token = res.data
        // console.log(token);
        var dict_id = []
        var dict_name = []
         for (let t = 0; t < token.length; t++) {
          if (token[t] != '' && token[t] != ' ') {
            // console.log(token[t]);
            var prodata = {
          name: token[t],
          status:1,
        };
        await DictService.getdicts('',token[t]).then(async (res)=>{
          // console.log(res.data);
          if (res.data.length > 0) {
            dict_id.push(res.data[0].id)
            dict_name.push(res.data[0].name)
          }else{
  DictService.createdict(prodata).then((res) => {
    // console.log(res.data);
    dict_id.push(res.data.id)
    dict_name.push(token[t])
})
          }
           if (t+1 == token.length) {
              console.log(dict_id);
              console.log(dict_name);
                var maprule = {
        keyword_id: 1,
        advertise_id: 0,
        status: 1,
        answer: 1,
        user: this.currentUser.id,
        map_dict: dict_id,
        statusfalse:0,
        statustrue:0,
        weight:0,
        map_advertise: '',
      };
      console.log(datas);
      console.log(maprule);
      var sql = `SELECT m.* FROM map_rule_based m WHERE m.status = 1 and m.map_dict = '[${dict_id}]'`;
            console.log(sql);

            await RuleBasedService.getbydict(sql).then((res) => {
console.log(res.data);
if (res.data.length == 0) {
  MapRuleBasedService.createmap_rule_based(maprule).then(
              async (res) => {
                console.log(res.data);
                var map_id = res.data.id;
                datas.rulebasedId = map_id
                var sendata = dict_id;
                // console.log(sendata);
                // console.log(dict_name);
                for (let d = 0; d < sendata.length; d++) {
                  // console.log(sendata[d]);
                  if (sendata[d]) {
                    await DictService.getdict(sendata[d]).then(async (sen) => {
                    // console.log(sen.data);
                    var rule = {
                      map_rule_based_id: map_id,
                      dict_id: sendata[d],
                      dict_name: sen.data.name,
                      no: d + 1,
                    };
                    // console.log(rule);
                    await RuleBasedService.createrule_based(rule).then(async () => {
                      if (d + 1 == sendata.length) {
                        
       document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
                      }
                    });
                  });
                  }
                }
              }
            );
}else{
  alert('มีกฎนี้ในระบบแล้ว')
}
            });
      
            }
        });
        }
        }
        });
       
            }
    },
    updatetoken(data,type){
      // console.log(data);
      var tokenize= ''
      // data.name = data.name.replaceAll(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
      // data.name = data.name.replaceAll(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');

      axios.get(LinkService.getpythonlink()+'/tokenkeyword?text=' + data.name).then((res) => {
        this.tokenize = res.data
        // console.log(res.data);
        tokenize = res.data.replaceAll(" ",'')
        tokenize = tokenize.replaceAll(".",'')
        tokenize = tokenize.replaceAll("''",'')
        tokenize = tokenize.replaceAll(",,",',')
        tokenize = tokenize.replaceAll('[','')
        tokenize = tokenize.replaceAll(']','')
        tokenize = tokenize.replaceAll("'",'')
        tokenize = tokenize.replaceAll(",",' | ')
      var tokendata = {
        name:data.name,
        token: tokenize,
        };
        // console.log(tokendata);
        // console.log(data.id);
        KeywordService.updatekeyword(data.id, tokendata).then(() => {
          // console.log(res.data);
          if (type == 'update') {
            document.getElementById("closedcategory").click();
            this.getcategory();
            alert('บันทึกสำเร็จ')
          }
        });
      });
    },
    getcategory(){
      MapRuleBasedService.getallrulebased().then((res)=>{
        this.list = res.data
        RuleBasedService.getweightrulebasemax().then((res)=>{
          console.log('rulebased',res.data.max);
        })
        RuleBasedService.getweightkeywordmax().then((res)=>{
          console.log('keyword',res.data.max);
        })
        // console.log(res.data);
        // for (let l = 0; l < this.list.length; l++) {
          // this.updatetoken(this.list[l])
          // for (let t = 0; t < this.list[l].token.length; t++) {
          //   console.log(this.list[l].token[t]);
            
          // }
          
        // }
      })
    },
  },
  mounted() {
    this.getcategory()
  },
};
</script>

<style>
html,
body {
  height: 100%;
  margin: 0px;
}</style>
