import Vue from "vue";
import Router from "vue-router";
import Import from './components/Import.vue';
import scraping from './components/scraping.vue';
import category from './components/category.vue';
import scopus from './components/scopus.vue';
import scrapingdelete from './components/scrapingdelete.vue';
import searchbyfda from './components/searchbyfda.vue';
import Dashboard from './components/Dashboard.vue';
import DetailTypeGraph from './components/DetailTypeGraph.vue';
import DetailTypeTwoGraph from './components/DetailTypeTwoGraph.vue';
import keywords from './components/keywords.vue';
import Advertising from './components/Advertising.vue';
import rulebased from './components/rulebased.vue';
import Advertise from './components/Advertise.vue';
import keywordrulebased from './components/keywordrulebased.vue';
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import Menumanage from './components/Menumanage.vue';
import check from './components/check.vue';
import token from './components/token.vue';
import producttoken from './components/producttoken.vue';
import producttokenlink from './components/producttokenlink.vue';
import trainingscores from './components/trainingscores.vue';
import addproducttoken from './components/addproducttoken.vue';
import products from './components/products.vue';
import product from './components/product.vue';
import productcheck from './components/productcheck.vue';
import typesproduct from './components/typesproduct.vue';
import test from './components/test.vue';
import productstrain from './components/productstrain.vue';
import productstest from './components/productstest';
import productresult from './components/productresult';
import productstrainall from './components/productstrainall.vue';
import rulebasedexpert from './components/rulebasedexpert.vue';
import testproduct from './components/testproduct.vue';
import productsgetstatus from './components/productsgetstatus.vue';
import productsevaluate from './components/productsevaluate.vue';
import productspretrain from './components/productspretrain.vue';
import productpre from './components/productpre.vue';
import addproducttokenreal from './components/addproducttokenreal.vue';
import productsreal from './components/productsreal.vue';
import productstestreal from './components/productstestreal.vue';
import rulebasedreal from './components/rulebasedreal.vue';
import productreal from './components/productreal.vue';
import productsevaluatereal from './components/productsevaluatereal.vue';
import productspretrainreal from './components/productspretrainreal.vue';
import Home from './components/Home.vue';
import AboutUs from './components/AboutUs.vue';
import Contact from './components/Contact.vue';
import ManageHome from './components/ManageHome.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/import",
      alias: "/import",
      name: "import",
      component: Import
    },
    {
      path: "/check",
      alias: "/check",
      name: "check",
      component: check
    },
    {
      path: "/scraping",
      alias: "/scraping",
      name: "scraping",
      component: scraping
    },
    {
      path: "/category",
      alias: "/category",
      name: "category",
      component: category
    },
    {
      path: "/Corpus",
      alias: "/Corpus",
      name: "Corpus",
      component: scopus
    },
    {
      path: "/scrapingdelete",
      alias: "/scrapingdelete",
      name: "scrapingdelete",
      component: scrapingdelete
    },
    {
      path: "/searchbyfda",
      alias: "/searchbyfda",
      name: "searchbyfda",
      component: searchbyfda
    },
    {
      path: "/Dashboard",
      alias: "/Dashboard",
      name: "Dashboard",
      component: Dashboard
    },
    {
      path: "/detailtype/:id",
      alias: "/detailtype",
      name: "detailtype",
      component: DetailTypeGraph
    },  
    {
      path: "/detailtypetwo/:id",
      alias: "/detailtypetwo",
      name: "detailtypetwo",
      component: DetailTypeTwoGraph
    },
    {
      path: "/keywords",
      alias: "/keywords",
      name: "keywords",
      component: keywords
    },
    {
      path: "/Advertising",
      alias: "/Advertising",
      name: "Advertising",
      component: Advertising
    },
    {
      path: "/rulebased",
      alias: "/rulebased",
      name: "rulebased",
      component: rulebased
    },
    
    {
      path: "/Advertise",
      alias: "/Advertise",
      name: "Advertise",
      component: Advertise
    },
    {
      path: "/keywordrulebased",
      alias: "/keywordrulebased",
      name: "keywordrulebased",
      component: keywordrulebased
    },
    {
      path: "/login",
      alias: "/Login",
      name: "Login",
      component: Login
    },
    {
      path: "/Users",
      alias: "/Users",
      name: "Users",
      component: Users
    },
    {
      path: "/menu",
      alias: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/menumanage",
      alias: "/menumanage",
      name: "menumanage",
      component: Menumanage
    },
    {
      path: "/token",
      alias: "/token",
      name: "token",
      component: token
    },
    {
      path: "/producttoken",
      alias: "/producttoken",
      name: "producttoken",
      component: producttoken
    },
    {
      path: "/producttokenlink",
      alias: "/producttokenlink",
      name: "producttokenlink",
      component: producttokenlink
    },
    {
      path: "/trainingscores",
      alias: "/trainingscores",
      name: "trainingscores",
      component: trainingscores
    },
    {
      path: "/addproducttoken",
      alias: "/addproducttoken",
      name: "addproducttoken",
      component: addproducttoken
    },
    {
      path: "/add",
      alias: "/add",
      name: "add",
      component: addproducttokenreal
    },
    {
      path: "/testproduct",
      alias: "/testproduct",
      name: "testproduct",
      component: testproduct
    },
    {
      path: "/products",
      alias: "/products",
      name: "products",
      component: products
    },
    {
      path: "/allproduct",
      alias: "/allproduct",
      name: "allproduct",
      component: productsreal
    },
    {
      path: "/productstrain",
      alias: "/productstrain",
      name: "productstrain",
      component: productstrain
    },
    {
      path: "/productstrainall",
      alias: "/productstrainall",
      name: "productstrainall",
      component: productstrainall
    },
    {
      path: "/pd",
      alias: "/pd",
      name: "pd",
      component: productstestreal
    },
    {
      path: "/productstest",
      alias: "/productstest",
      name: "productstest",
      component: productstest
    },
    {
      path: "/productresult/:id",
      alias: "/productresult",
      name: "productresult",
      component: productresult
    },
    {
      path: "/product/:id",
      alias: "/product",
      name: "product",
      component: product
    },
    {
      path: "/productpre/:id/:typebase",
      alias: "/productpre",
      name: "productpre",
      component: productpre
    },
    {
      path: "/productreal/:id/:typebase",
      alias: "/productreal",
      name: "productreal",
      component: productreal
    },
    {
      path: "/productcheck/:id",
      alias: "/productcheck",
      name: "productcheck",
      component: productcheck
    },
    {
      path: "/typesproduct",
      alias: "/typesproduct",
      name: "typesproduct",
      component: typesproduct
    },
    {
      path: "/result",
      alias: "/result",
      name: "result",
      component: rulebasedreal
    },
    {
      path: "/test",
      alias: "/test",
      name: "test",
      component: test
    },
    {
      path: "/rulebasedexpert",
      alias: "/rulebasedexpert",
      name: "rulebasedexpert",
      component: rulebasedexpert
    },
    {
      path: "/productsgetstatus",
      alias: "/productsgetstatus",
      name: "productsgetstatus",
      component: productsgetstatus
    },
    {
      path: "/productsevaluatereal",
      alias: "/productsevaluatereal",
      name: "productsevaluatereal",
      component: productsevaluatereal
    },
    {
      path: "/productsevaluate",
      alias: "/productsevaluate",
      name: "productsevaluate",
      component: productsevaluate
    },
    {
      path: "/productspretrain",
      alias: "/productspretrain",
      name: "productspretrain",
      component: productspretrain
    },
    {
      path: "/productspretrainreal",
      alias: "/productspretrainreal",
      name: "productspretrainreal",
      component: productspretrainreal
    },
    {
      path: "/home",
      alias: "/home",
      name: "home",
      component: Home
    },
    {
      path: "/about",
      alias: "/about",
      name: "about",
      component: AboutUs
    },
    {
      path: "/contact",
      alias: "/contact",
      name: "contact",
      component: Contact
    },
    {
      path: "/managehome",
      alias: "/managehome",
      name: "managehome",
      component: ManageHome
    }
  ]
});


router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/register','/keywords','/testproduct','/home','/about','/contact'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('fda');
  // trying to access a restricted page + not logged in
  // redirect to login page
  // console.log(loggedIn);
  if (authRequired && !loggedIn) {
    next('/home');
    // next();
  } else {
    next();
  }

});

export default router;
