import http from "../http-common";

class MapRuleBasedUserTestService {
  getmap_rule_based_user_tests(id) {
    return http.get('/map_rule_based_user_test?name='+id);
  }
  getmap_rule_based_user_test(id) {
    return http.get('/map_rule_based_user_test/'+id);
  }
  createmap_rule_based_user_test(data) {
    return http.post('/map_rule_based_user_test', data);
  } 
  updatemap_rule_based_user_test(id,data) {
    return http.put(`/map_rule_based_user_test/${id}`, data);
  }
  deletemap_rule_based_user_test(id) {
    return http.delete(`/map_rule_based_user_test/${id}`);
  }
}

export default new MapRuleBasedUserTestService();

